<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="30px" max-width="30px" alt="logo" contain class="me-3"></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>

      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- tree -->
            <v-img height="226" width="300" class="auth-tree" src="@/assets/images/misc/tree-4.png"></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">{{ companyName }}!</p>
                  <p v-t="'pleaseConfirmYourAccount'" class="mb-2" />
                </v-card-text>

                <v-card-text>
                  <div v-if="loading" class="Confirm-recruiter__svg">
                    <v-progress-circular :width="6" :size="100" color="amber" indeterminate></v-progress-circular>
                  </div>
                  <div v-if="accountConfirmed && !loading">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                      class="Confirm-recruiter__svg"
                    >
                      <circle
                        class="Confirm-recruiter__path Confirm-recruiter__path--circle"
                        fill="none"
                        stroke="#73AF55"
                        stroke-width="6"
                        stroke-miterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <polyline
                        class="Confirm-recruiter__path Confirm-recruiter__path--check"
                        fill="none"
                        stroke="#73AF55"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        points="100.2,40.2 51.5,88.8 29.8,67.5 "
                      />
                    </svg>
                    <p
                      v-if="!accountHasBeenAlreadyConfirmed"
                      v-t="'accountConfirmed'"
                      class="Confirm-recruiter__p--success"
                    />
                    <p v-else v-t="'accountHasBeenAlreadyConfirmed'" class="Confirm-recruiter__p--success" />
                  </div>
                  <div v-else-if="!accountConfirmed && !loading">
                    <svg
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 130.2 130.2"
                      class="Confirm-recruiter__svg"
                    >
                      <circle
                        class="Confirm-recruiter__path Confirm-recruiter__path--circle"
                        fill="none"
                        stroke="#D06079"
                        stroke-width="6"
                        stroke-miterlimit="10"
                        cx="65.1"
                        cy="65.1"
                        r="62.1"
                      />
                      <line
                        class="Confirm-recruiter__path Confirm-recruiter__path--line"
                        fill="none"
                        stroke="#D06079"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        x1="34.4"
                        y1="37.9"
                        x2="95.8"
                        y2="92.3"
                      />
                      <line
                        class="Confirm-recruiter__path Confirm-recruiter__path--line"
                        fill="none"
                        stroke="#D06079"
                        stroke-width="6"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        x1="95.8"
                        y1="38"
                        x2="34.4"
                        y2="92.2"
                      />
                    </svg>
                    <p v-t="'somethingWentWrongResendConfirmationEmail'" class="Confirm-recruiter__p--error" />
                    <v-form ref="emailForm" @submit.prevent="handleFormSubmit">
                      <v-text-field
                        v-model="email"
                        outlined
                        :label="$t('email', { postfix: '' })"
                        :placeholder="$t('email', { postfix: '' })"
                        :rules="[validators.required, validators.emailValidator]"
                        hide-details="auto"
                        class="mb-6"
                        maxlength="254"
                      ></v-text-field>
                      <v-btn block color="primary" type="submit" class="mt-6" :loading="resendConfirmationEmailLoading">
                        {{ $t('resendConfirmationEmail') }}
                      </v-btn>
                    </v-form>
                  </div>
                </v-card-text>

                <v-card-text
                  v-if="accountConfirmed && !loading"
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p v-t="'everythingDoneHere'" class="mb-0 me-2" />
                  <router-link :to="{ name: 'auth-login' }"> {{ $t('signIn') }}</router-link>
                </v-card-text>
                <v-card-actions v-else-if="!accountConfirmed && !loading" class="d-flex justify-center align-center">
                  <router-link :to="{ name: 'auth-login' }" class="d-flex align-center text-sm">
                    <v-icon size="24" color="primary">
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span v-t="'backToLogin'" />
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'
import { useActions } from 'vuex-composition-helpers'
import { axiosIns } from '@/plugins/axios'

export default {
  setup() {
    const { router } = useRouter()

    const emailForm = ref(null)
    const email = ref('')

    const loading = ref(false)
    const resendConfirmationEmailLoading = ref(false)
    const companyName = ref('')
    const accountConfirmed = ref(false)
    const accountHasBeenAlreadyConfirmed = ref(false)
    const showLoginButton = ref(false)

    const { resendRecruiterConfirmationEmail } = useActions('recruiter', ['resendRecruiterConfirmationEmail'])

    onMounted(async () => {
      try {
        loading.value = true
        const { data } = await axiosIns.get('company/current/name/')
        companyName.value = data.name
        await axiosIns.patch(`recruiter/${router.currentRoute.params.id}/confirm-account/`)

        accountConfirmed.value = true
        accountHasBeenAlreadyConfirmed.value = false
        showLoginButton.value = true
        loading.value = false
      } catch (e) {
        loading.value = false
        accountConfirmed.value = false
        accountHasBeenAlreadyConfirmed.value = false
        if (e.response.data.detail === 'Account is already activated') {
          accountConfirmed.value = true
          accountHasBeenAlreadyConfirmed.value = true
          showLoginButton.value = true
        } else {
          accountConfirmed.value = false
          accountHasBeenAlreadyConfirmed.value = false
          showLoginButton.value = false
        }
      }
    })

    const handleFormSubmit = async () => {
      const isFormValid = emailForm.value.validate()

      if (!isFormValid) return

      resendConfirmationEmailLoading.value = true
      await resendRecruiterConfirmationEmail(email.value)
      showLoginButton.value = true
      resendConfirmationEmailLoading.value = false
    }

    return {
      handleFormSubmit,
      emailForm,

      companyName,
      email,
      loading,
      resendConfirmationEmailLoading,
      accountHasBeenAlreadyConfirmed,
      accountConfirmed,
      validators: {
        required,
        emailValidator,
      },
      icons: {
        mdiChevronLeft,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.Confirm-recruiter {
  &__svg {
    width: 100px;
    display: block;
    margin: 40px auto 0;
  }

  &__path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
    &--circle {
      -webkit-animation: dash 0.9s ease-in-out;
      animation: dash 0.9s ease-in-out;
    }
    &--line {
      stroke-dashoffset: 1000;
      -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
      animation: dash 0.9s 0.35s ease-in-out forwards;
    }
    &--check {
      stroke-dashoffset: -100;
      -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
      animation: dash-check 0.9s 0.35s ease-in-out forwards;
    }
  }

  &__p {
    &--success {
      text-align: center;
      margin: 20px 0 20px;
      font-size: 1em;
      color: #73af55;
    }
    &--error {
      text-align: center;
      margin: 20px 0 20px;
      font-size: 1em;
      color: #d06079;
    }
  }

  @-webkit-keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 1000;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @-webkit-keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }

  @keyframes dash-check {
    0% {
      stroke-dashoffset: -100;
    }
    100% {
      stroke-dashoffset: 900;
    }
  }
}
</style>
